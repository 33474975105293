<template>
  <div class="step-panel">
    <div class="step-header">Disbursement Date & Bank Account</div>
    <div class="step-content abs-center height-m-500 pa-2">
      <div class="option-panel">
        <div class="section">
          <div class="section-content">
            <web-date-select-box
              v-model="layouts.data.effectiveDate"
              :availableDate="layouts.data.effectiveDateArray"
              label="Disbursement Date"
            ></web-date-select-box>
            <v-textarea
              v-model="layouts.data.bankAccount.bankName"
              :readonly="true"
              rows="2"
              auto-grow
              :label="individualTransactionMode === '1' ? 'Disbursement Account' : 'Bank Account'"
            ></v-textarea>
            <v-textarea
              v-if="individualTransactionMode === '1'"
              v-model="layouts.data.bankAccount.bankName"
              :readonly="true"
              rows="2"
              auto-grow
              label="Repayment Account"
            ></v-textarea>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import WebDateSelectBox from '@/components/base/WebDateSelectBox'
import { InfrastructureApi, OnlineApi } from '@/api'
import { Tool } from '@/assets/js/util'
export default {
  name: 'ChooseBankAccount',
  components: {
    WebDateSelectBox
  },
  data: () => ({
    assist: {
      tool: new Tool()
    },
    layouts: {
      data: {
        effectiveDateArray: [],
        effectiveDate: '',
        bankAccount: {
          bankName: '',
          bankId: null
        },
        portfolio: {}
      }
    }
  }),
  computed: {
    individualTransactionMode () {
      return this.layouts.data.portfolio.individualTransactionMode
    }
  },
  watch: {
    'store.getters.getPortfolioJson': {
      handler (val) {
        if (this.assist.tool.isNotEmpty(val)) {
          this.layouts.data.portfolio = val
        }
      },
      immediate: true,
      deep: true
    },
    'layouts.data.effectiveDate': {
      handler (val) {
        this.$store.commit('setLoan', { ...this.$store.getters.getLoan, effectiveDate: val })
      }
    },
    'store.getters.getEffectiveDateChange': {
      handler (val) {
        if (this.assist.tool.isNotEmpty(val)) {
          this.getEffectiveDate()
          this.getDefaultDisbursementDate()
        }
      }
    }
  },
  mounted () {
    this.getEffectiveDate()
    this.getDefaultDisbursementDate()
    this.listBankData()
  },
  methods: {
    getEffectiveDate () {
      InfrastructureApi.getAvailableDate(
        {
          portfolioId: this.$store.getters.getPortfolioId
        }, (result) => {
          this.layouts.data.effectiveDateArray = []
          for (const item of result) {
            if (this.assist.tool.isNotEmpty(item)) {
              this.layouts.data.effectiveDateArray.push(item) // 须符合select组件的数据结构
            }
          }
        })
    },
    getDefaultDisbursementDate () {
      InfrastructureApi.getDefaultDisbursementDate({ portfolioId: this.$store.getters.getPortfolioId }, (res) => {
        if (this.assist.tool.isNotEmpty(res)) {
          this.layouts.data.effectiveDate = res
          this.$store.commit('setLoan', { ...this.$store.getters.getLoan, effectiveDate: res })
        }
      }, () => {
      })
    },
    listBankData () {
      OnlineApi.getBankInfo(this.$store.getters.getLoan.loanNo, (result) => {
        if (this.assist.tool.isNotEmpty(result)) {
          const accountNo = result.accountNo
          const bankName = this.assist.tool.isNotEmpty(result.bankName) ? result.bankName : ''
          this.layouts.data.bankAccount.bankName = bankName + '\n' + '( ******' + accountNo.substring(accountNo.length - 4) + ' )'
        }
      })
    }
  }
}
</script>

<style lang="sass" scoped>
.step-panel
  display: grid
  grid-template-rows: 1fr 5fr
  padding: 24px 12px

  & .step-header
    font-size: 14px
    font-weight: 400
    text-align: center
    color: rgba(2, 2, 2, 0.6)

  & .step-content
    padding: 16px
</style>

<style lang="css">
.section-content textarea {
  height: 54px!important;
}
</style>
