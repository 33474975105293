import { render, staticRenderFns } from "./ChooseLoanAmount.vue?vue&type=template&id=2b6c8753&scoped=true"
import script from "./ChooseLoanAmount.vue?vue&type=script&lang=js"
export * from "./ChooseLoanAmount.vue?vue&type=script&lang=js"
import style0 from "./ChooseLoanAmount.vue?vue&type=style&index=0&id=2b6c8753&prod&lang=sass&scoped=true"
import style1 from "./ChooseLoanAmount.vue?vue&type=style&index=1&id=2b6c8753&prod&lang=sass"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2b6c8753",
  null
  
)

export default component.exports