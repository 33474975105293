<template>
  <div class="step-panel">
    <div class="step-header">Loan Amount</div>
    <div class="step-content abs-center height-m-500">
      <div class="round-slider-ext">
        <div class="arc-text-container">
          <div id="arc-text-pre-qualified"></div>
          <v-spacer></v-spacer>
          <div id="arc-text-step">$25.00 INCREMENTS</div>
        </div>
        <round-slider
          v-model="layouts.data.roundSliderDefinition.sliderValue"
          :min="layouts.data.roundSliderDefinition.min"
          :max="layouts.data.roundSliderDefinition.max"
          :disabled="layouts.actives.selectAmount"
          startAngle="90"
          endAngle="+360"
          radius="120"
          width="32"
          lineCap="round"
          step="25"
          editableTooltip="false"
          range-color="var(--theme_secondary)"
          :tooltipFormat="currencyFormat"
        />
      </div>

      <div class="coupon-tip pa-1 mt-5 animate__animated animate__headShake animate__infinite animate__slow" v-if="layouts.data.couponTip">
        <v-icon color="var(--theme_primary)">mdi-party-popper</v-icon>
        <div class="pl-2 text-white">A coupon has been applied for your loan.</div>
      </div>
    </div>

    <div class="step-footer">
      <v-alert
        dense
        text
        border="left"
        color="var(--theme_primary)"
        class="ma-0 f-s-12"
      >
        Please choose an amount with a minimum of <strong>{{
          assist.currency.format(layouts.data.roundSliderDefinition.min)
        }}</strong> and up to
        <strong>{{ assist.currency.format(layouts.data.roundSliderDefinition.max) }}</strong>.
      </v-alert>
    </div>
  </div>
</template>

<script>
import RoundSlider from 'vue-round-slider'
import ArcText from 'arc-text'
import { Currency, Tool, DataType } from '@/assets/js/util'
import { OnlineApi } from '@/api'

export default {
  name: 'ChooseLoanAmount',
  components: {
    RoundSlider
  },
  data: () => ({
    assist: {
      currency: new Currency(),
      tool: new Tool(),
      dataType: DataType
    },
    layouts: {
      data: {
        roundSliderDefinition: {
          min: 200,
          max: 201,
          sliderValue: 200
        },
        couponTip: false
      },
      actives: {
        selectAmount: false
      }
    }
  }),
  watch: {
    'layouts.data.roundSliderDefinition.sliderValue': {
      handler (val) {
        this.$store.commit('setApprovedAmount', val)
      }
    }
  },
  mounted () {
    const stepArcText = new ArcText(document.getElementById('arc-text-step'))
    stepArcText.arc(85).direction(-1)
    this.listProgram()
    this.checkCouponStatus()
  },
  methods: {
    checkCouponStatus () {
      OnlineApi.checkCoupon((res) => {
        this.layouts.data.couponTip = res === this.assist.dataType.COUPON_EXIST.EXIST.value
      })
    },
    listProgram () {
      this.$store.commit('setApprovedAmount', this.layouts.data.roundSliderDefinition.sliderValue)
      OnlineApi.getLoanInfo(
        this.$store.getters.getLoan.loanNo,
        (result) => {
          if (this.assist.tool.isEmpty(result)) return

          if (this.assist.tool.isNotEmpty(result.qualifiedPrincipal)) {
            this.layouts.data.roundSliderDefinition.max = result.qualifiedPrincipal
          } else {
            this.layouts.data.roundSliderDefinition.max = 200
            this.layouts.actives.selectAmount = true
            this.$emit('disabledBtn', true)
          }
          document.getElementById('arc-text-pre-qualified').innerText = `PRE-QUALIFIED FOR ${this.assist.currency.format(result.qualifiedPrincipal)}`
          this.preQualifiedArcText = new ArcText(document.getElementById('arc-text-pre-qualified'))
          this.preQualifiedArcText.arc(85)
          this.layouts.data.roundSliderDefinition.sliderValue = result.qualifiedPrincipal || 200
          this.$store.commit('setApprovedAmount', this.layouts.data.roundSliderDefinition.sliderValue)
        },
        () => {
          this.layouts.data.roundSliderDefinition.max = 200
          this.layouts.actives.selectAmount = true
          this.$emit('disabledBtn', true)
        }
      )
    },
    currencyFormat (e) {
      return '<div class="round-slider-label">' +
        '<span class="amount">' + this.assist.currency.format(e.value).replace('$', '') + '</span>' +
        '</div>'
    }
  }
}
</script>

<style lang="sass" scoped>
.step-panel
  display: grid
  grid-template-rows: 1fr 3fr 1fr
  padding: 24px 12px

  & .step-header
    font-size: 14px
    font-weight: 400
    text-align: center
    color: rgba(2, 2, 2, 0.6)

  & .step-content

    .round-slider-ext
      display: flex
      justify-content: center
      align-items: center
      margin: 0
      position: relative

      & .rs-tooltip-text
        font-size: 40px

    .arc-text-container
      position: absolute
      display: flex
      flex-flow: column
      height: 250px
      width: 250px
      padding: 40px
      font-size: 12px
      color: rgba(0, 0, 0, .4)

    .coupon-tip
      display: flex
      background: var(--theme_primary_opacity_low)
      border-radius: 5px

  & .step-footer
    line-height: 1.5
    align-self: end
</style>
<style lang="sass">
//style of loan amount of round-slider
.round-slider-label
  font-family: "PT Sans", serif
  font-size: 36px
  font-weight: 700
  line-height: 1.0
  display: flex
  align-items: flex-start
  padding-top: 20px
  color: var(--theme_secondary)

  & .amount:before
    font-size: 18px
    padding-top: 5px
    content: '$'

.tips-class
  color: red
</style>
