<template>
  <div class="payment-mode-group">
    <v-card
      v-for="(option, index) in options"
      :key="`PAYMENT_MODE_${ index }`"
      :class="`${ index === layouts.data.checkIndex ? 'active' : '' }`"
      class="payment-mode-card"
      @click="onClickPaymentMode(index)"
    >
      <v-card-text class="payment-mode-card--content">
        <v-layout>
          <v-flex lg2>
            <div class="checkbox-input">
              <div class="checkbox-input--square">
                <v-icon class="checkbox-input--status" color="var(--theme_secondary)">{{ index === layouts.data.checkIndex ? 'check' : '' }}</v-icon>
              </div>
            </div>
          </v-flex>
          <v-flex>
            <div class="checkbox-details">
              <div class="checkbox-details--label">
                {{ option.label }}
              </div>
              <div class="checkbox-details--description">
                {{ option.description }}
              </div>
            </div>
          </v-flex>
        </v-layout>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'PaymentModeGroup',
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    options: {
      type: Array,
      default: () => []
    },
    value: null
  },
  data () {
    return {
      layouts: {
        data: {
          checkIndex: null
        }
      }
    }
  },
  watch: {
    value (val) {
      this.layouts.data.checkIndex = val
    }
  },
  mounted () {
    this.layouts.data.checkIndex = this.value
  },
  methods: {
    onClickPaymentMode (index) {
      this.layouts.data.checkIndex = index
      this.$emit('change', index)
      this.$emit('click', index)
    }
  }
}
</script>

<style lang="sass" scoped>
  .payment-mode-card
    width: 100%
    border-radius: 12px
    &--content
      padding: 0
      border-radius: 12px!important
    & .checkbox-input
      display: flex
      justify-content: center
      align-items: center
      width: 48px
      height: 100%
      border-radius: 12px 0 0 12px
      background-color: #F4F5F9
      &--square
        display: flex
        justify-content: center
        align-items: center
        width: 24px
        height: 24px
        background-color: #FFFFFF
      &--status
        font-size: 16px
    & .checkbox-details
      padding: 16px
      border-radius: 0 12px 12px 0
      &--label
        font-size: 20px
        font-weight: 700
      &--description
        font-size: 14px
        opacity: 0.6
    &.active
      background-color: var(--theme_secondary)
      & .checkbox-input
        background-color: rgba(255, 255, 255, 0.2)
      & .checkbox-details--label
        color: #ffffff
        &--square
          background-color: #ffffff
        &--status
          color: var(--theme_primary)
      & .checkbox-details
        background-color: var(--theme_secondary)
        color: #F5F5F5
  .payment-mode-card + .payment-mode-card
    margin-top: 16px
</style>
<style>
.payment-mode-group .v-card--link:before{
  border-radius: 12px!important;
}
</style>
