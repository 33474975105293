<template>
  <div class="step-panel">
    <div class="step-header">Payment Options</div>
    <div class="step-content abs-center height-m-500">
      <div class="option-panel">
        <div class="section">
          <label class="section-title">Repayment Option</label>
          <payment-mode-group
            v-model="layouts.data.mode.repayment"
            :options="[{ id: 1, label: 'Electronic', description: 'Payment will be charged through ACH.' }, { id: 2, label: 'Check by Mail', description: 'Payment will be sent by paper check.' }]"
            @click="onClickRepaymentMod"
          ></payment-mode-group>
        </div>
        <div class="section">
          <label class="section-title">Funding Option</label>
          <payment-mode-group
            v-model="layouts.data.mode.funding"
            :options="[{ id: 1, label: 'Electronic', description: 'Funds will be deposited within 24 hours of approval. *' }, { id: 2, label: 'Check by Mail', description: 'Funds will be sent through check by mail.' }]"
            @click="onClickFundingMod"
          ></payment-mode-group>
        </div>
      </div>
    </div>
    <div class="step-footer">
      <v-alert
        dense
        text
        border="left"
        color="var(--theme_primary)"
        class="ma-0 f-s-12"
      >
        <span class="red-dot">*</span>Unavoidable delays that occur due to bank holidays, processing schedule of your bank,
        processing errors, "acts of God" and/or "acts of terror" may extend the time for the deposit.
      </v-alert>
    </div>

    <!-- Check By Mail First Dialog-->
    <v-dialog
      persistent
      max-width="500"
      v-model="layouts.dialog.checkMail"
    >
      <v-card flat class="b-r-16 p-10">
        <v-card-text class="f-s-24 bold-f p-t-24 text-center">Warning</v-card-text>
        <v-card-text v-html="layouts.dialog.checkMailText">
        </v-card-text>
        <v-card-actions>
          <web-btn :height="50" :theme="'normal'" :width="140" @click="onClickNo">No</web-btn>
          <v-spacer></v-spacer>
          <web-btn :height="50" :width="140" @click="layouts.dialog.chooseTime = true; layouts.dialog.checkMail = false">
            Yes
          </web-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Check By Mail Second Dialog-->
    <v-dialog
      persistent
      max-width="500"
      v-model="layouts.dialog.chooseTime">
      <v-card class="pa-4">
        <v-card-text>
          We need more information based on your choice of repayment, please contact us at
          {{ layouts.data.portfolio.tollfreePhone }}.
        </v-card-text>
        <v-card-text>
          Please select the best time and phone number to reach you.
        </v-card-text>
        <v-card-actions>
          <template>
            <v-row
              align="center"
              justify="space-around"
            >
              <div @click="layouts.dialog.timerDialog = true" style="cursor: pointer">
                <div class="v-picker__title black">
                  <div class="v-time-picker-title__time">
                    <div class="v-picker__title__btn v-picker__title__btn--active">{{ hour }}</div>
                    <span>:</span>
                    <div class="v-picker__title__btn">{{ minute }}</div>
                  </div>
                </div>
              </div>
            </v-row>
          </template>
        </v-card-actions>
        <v-card-text>
          <ValidationObserver ref="observer">
            <ValidationProvider
              v-slot="{ errors }"
              name="Primary Phone"
              rules="required|phone"
            >
              <web-text-field-box
                v-model="layouts.data.cellPhone"
                v-mask="'(###) ###-####'"
                label="Primary Phone"
                :error-messages="errors[0]"
              ></web-text-field-box>
            </ValidationProvider>
            <ValidationProvider
              v-slot="{ errors }"
              name="Secondary Phone"
              rules="required|phone"
            >
              <web-text-field-box
                v-model="layouts.data.homePhone"
                v-mask="'(###) ###-####'"
                label="Secondary Phone"
                :error-messages="errors[0]"
              ></web-text-field-box>
            </ValidationProvider>
          </ValidationObserver>
        </v-card-text>
        <v-card-actions>
          <web-btn :height="50" :theme="'normal'" :width="140" @click="onClickTimerNo">No</web-btn>
          <v-spacer></v-spacer>
          <web-btn :height="50" :width="140" @click="onPaperCheckSubmit">Yes</web-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Time Pick Dialog-->
    <v-dialog
      max-width="700"
      v-model="layouts.dialog.timerDialog">
      <v-time-picker
        v-model="layouts.data.timePicker"
        color="secondary"
        format="24hr"
        width="350"
        max="22:15"
        min="9:30"
        @click:minute="layouts.dialog.timerDialog = false"
      ></v-time-picker>
    </v-dialog>
  </div>
</template>

<script>
import PaymentModeGroup from '@/components/common/PaymentModeGroup'
import { Tool, DataType } from '@/assets/js/util'
import WebTextFieldBox from '@/components/base/WebTextFieldBox'
import webBtn from '@/components/base/WebBtn'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { OnlineApi } from '@/api'

export default {
  name: 'ChoosePaymentOptions',
  components: {
    PaymentModeGroup,
    WebTextFieldBox,
    ValidationObserver,
    ValidationProvider,
    webBtn
  },
  data: () => ({
    assist: {
      tool: new Tool()
    },
    layouts: {
      data: {
        chooseOption: 'Payment',
        mode: {
          repayment: null,
          funding: null
        },
        portfolio: {},
        timePicker: '',
        cellPhone: '',
        homePhone: ''
      },
      actives: {
        repaymentActive: false,
        fundingActive: false
      },
      dialog: {
        checkMail: false,
        checkMailText: '',
        timerDialog: false
      }
    }
  }),
  computed: {
    hour () {
      if (this.layouts.data.timePicker) {
        return this.layouts.data.timePicker.split(':')[0]
      } else {
        return '--'
      }
    },
    minute () {
      if (this.layouts.data.timePicker) {
        return this.layouts.data.timePicker.split(':')[1]
      } else {
        return '--'
      }
    }
  },
  watch: {
    'store.getters.getPortfolioJson': {
      handler (val) {
        if (this.assist.tool.isNotEmpty(val)) {
          this.layouts.data.portfolio = val
        }
      },
      immediate: true,
      deep: true
    }
  },
  mounted () {
    this.getContactInfo()
  },
  methods: {
    onClickRepaymentMod (index) {
      if (index === 1) {
        this.layouts.dialog.checkMail = true
        this.layouts.actives.repaymentActive = false
        this.layouts.data.chooseOption = 'Repayment'
        this.layouts.dialog.checkMailText = 'If you choose physical repayment, you' + '<span class="f-w-800"> cannot receive your loan proceeds electronically</span>' +
          '. If approved, your loan proceeds will be mailed to you in' + '<span class="f-w-800"> 5 business days</span>' + '. Other limitations apply.'
      } else {
        this.layouts.actives.repaymentActive = true
      }

      if (this.layouts.actives.fundingActive && this.layouts.actives.repaymentActive) {
        this.$emit('visible', 'stepThreeAndFour')
      } else {
        this.$emit('hidden')
      }
    },
    onClickFundingMod (index) {
      if (index === 1) {
        this.layouts.dialog.checkMail = true
        this.layouts.actives.fundingActive = false
        this.layouts.data.chooseOption = 'Funding'
        this.layouts.dialog.checkMailText = 'If approved, your loan proceeds will be mailed to you in' + '<span class="f-w-800"> 5 business days</span>. ' + 'Other limitations apply.'
      } else {
        this.layouts.actives.fundingActive = true
      }

      if (this.layouts.actives.fundingActive && this.layouts.actives.repaymentActive) {
        this.$emit('visible', 'stepThreeAndFour')
      } else {
        this.$emit('hidden')
      }
    },
    onClickNo () {
      this.layouts.dialog.checkMail = false
      if (this.layouts.data.chooseOption === 'Repayment') {
        this.layouts.data.mode.repayment = null
      } else {
        this.layouts.data.mode.funding = null
      }
      this.getContactInfo()
    },
    onClickTimerNo () {
      this.layouts.dialog.chooseTime = false
      if (this.layouts.data.chooseOption === 'Repayment') {
        this.layouts.data.mode.repayment = null
      } else {
        this.layouts.data.mode.funding = null
      }
      this.getContactInfo()
    },
    getContactInfo () {
      OnlineApi.getPersonalData((result) => {
        if (this.assist.tool.isNotEmpty(result)) {
          this.layouts.data.cellPhone = result.homePhone
          this.layouts.data.homePhone = result.cellPhone
        }
      })
    },
    onPaperCheckSubmit () {
      if (this.assist.tool.isNotEmpty(this.layouts.data.timePicker)) {
        this.layouts.dialog.timerDialog = false
        const request = {
          cellPhone: this.layouts.data.cellPhone.replace(/[^0-9]/ig, ''),
          homePhone: this.layouts.data.homePhone.replace(/[^0-9]/ig, ''),
          defaultDisbursementMode: DataType.TransactionModeEnum.PAPER_CHECK.value,
          defaultRepaymentMode: DataType.TransactionModeEnum.PAPER_CHECK.value
        }
        OnlineApi.paperCheck(request, () => {
          this.$router.push('/notification/204')
        }, () => {
        })
      }
    }
  }
}
</script>

<style lang="sass" scoped>
.red-dot
  color: #ff5050
.step-panel
  display: grid
  grid-template-rows: 1fr 12fr 2fr
  padding: 24px 12px
  background-color: rgba(154, 154, 154, 0.1)
  & .step-header
    font-size: 14px
    font-weight: 400
    text-align: center
    color: rgba(2, 2, 2, 0.6)

  & .step-content
    & .section
      & .section-title
        font-size: 12px
        color: rgba(2, 2, 2, .4)

    & .section + .section
      margin-top: 20px

  & .step-footer
    line-height: 1.5
    align-self: end
</style>
