<template>
  <div class="disbursement">
    <choose-loan-amount
      :class="`${ layouts.actives.displayStyle[0] }`"
      v-show="layouts.actives.displayShow.stepOneShow"
      @disabledBtn="(res) => layouts.actives.disabledBtn = res"
      >
    </choose-loan-amount>

    <choose-payment-options
      :class="`${ layouts.actives.displayStyle[1] }`"
      v-show="layouts.actives.displayShow.stepTwoShow"
      @visible="handlerVisible"
      @hidden="handlerHidden">
    </choose-payment-options>

    <choose-bank-account
      :class="`${ layouts.actives.displayStyle[2] }`"
      v-show="layouts.actives.displayShow.stepThreeShow"
    >

    </choose-bank-account>

    <!--    <choose-loan-term-->
    <!--      ref="loanTerms"-->
    <!--      class="step" :class="`${ layouts.actives.displayStyle[3] }`"-->
    <!--      v-show="layouts.actives.displayShow.stepFourShow"-->
    <!--      >-->
    <!--    </choose-loan-term>-->

    <div
      class="submit-panel" :class="`${ layouts.actives.displayStyle[3] }`"
      v-show="layouts.actives.displayShow.stepFourShow">
      <div class="action-panel">
        <web-btn
          width="60%"
          :height="80"
          :disabledControl="layouts.actives.disabledBtn"
          :loading="layouts.loading.submitBtn"
          @click="onClickSubmit"
        >
          <div>
            <div class="f-s-24">Submit</div>
            <div class="f-s-14">Application</div>
          </div>
        </web-btn>
      </div>
    </div>
  </div>
</template>

<script>
import ChooseLoanAmount from '@/views/disbursement/application/ChooseLoanAmount'
import ChoosePaymentOptions from '@/views/disbursement/application/ChoosePaymentOption'
import ChooseBankAccount from '@/views/disbursement/application/ChooseBankAccount'
import { Tool } from '@/assets/js/util'
import { OnlineApi } from '@/api'
import WebBtn from '@/components/base/WebBtn'

export default {
  name: 'Disbursement',
  components: {
    ChooseLoanAmount,
    ChoosePaymentOptions,
    ChooseBankAccount,
    // ChooseLoanTerm,
    WebBtn
  },
  data: () => ({
    assist: {
      tool: new Tool()
    },
    layouts: {
      data: {},
      actives: {
        displayStyle: [
          'visible',
          'visible',
          'hidden',
          'hidden'
        ],
        displayShow: {
          stepOneShow: true,
          stepTwoShow: true,
          stepThreeShow: false,
          stepFourShow: false,
          stepFiveShow: false,
          bankAccountShow: false
        },
        disabledBtn: false
      },
      settings: {},
      dialog: {},
      loading: {
        submitBtn: false
      }
    }
  }),
  computed: {},
  watch: {},
  mounted () {
  },
  methods: {
    handlerVisible () {
      this.layouts.actives.displayShow.stepFourShow = true
      this.layouts.actives.displayShow.stepThreeShow = true
      this.layouts.actives.displayStyle[2] = 'visible'
      this.layouts.actives.displayStyle[3] = 'visible'
    },
    handlerHidden () {
      this.layouts.actives.displayShow.stepFiveShow = false
      this.layouts.actives.displayShow.stepFourShow = false
      this.layouts.actives.displayShow.stepThreeShow = false
      this.layouts.actives.displayStyle[2] = 'hidden'
      this.layouts.actives.displayStyle[3] = 'hidden'
    },
    onClickSubmit () {
      const approvedPrincipal = this.$store.getters.getApprovedAmount
      if (approvedPrincipal % 25 !== 0) {
        return this.$store.commit('setPopupInformation', {
          message: 'The amount needs to be a multiple of 25.'
        })
      }

      this.layouts.loading.submitBtn = true
      const loan = this.$store.getters.getLoan
      const request = {
        disbursementDate: this.assist.tool.formatDateWithPattern(loan.effectiveDate, 'MM/DD/yyyy'),
        approvedPrincipal
      }
      OnlineApi.savePaymentOption(request, () => {
        this.layouts.loading.submitBtn = false
        this.$router.push('/disbursement/loan-details')
      }, (err) => {
        this.layouts.loading.submitBtn = false
        this.$store.commit('setPopupInformation', {
          message: err.message
        })
      })
    }
  }
}

</script>

<style lang="sass" scoped>
.disbursement
  display: grid
  grid-template-columns: repeat(4, 4fr)
  height: 100%
  width: 100%

  .action-panel
    height: 100%
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center

.hidden
  visibility: hidden
  animation: hide-step 1s 1

.visible
  visibility: visible
  animation: show-step 3s 1

@keyframes hide-step
  0%
    opacity: 1
  100%
    opacity: 0

@keyframes show-step
  0%
    opacity: 0
  100%
    opacity: 1

.submit-panel
  background: rgba(154, 154, 154, 0.1)
</style>
